import React, { useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { useNavigate } from 'react-router-dom';
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Label } from "../components/ui/label";
import { Spinner } from '@chakra-ui/react'
import axios from 'axios';



const supabase = createClient(process.env.REACT_APP_SUPABASE_URL!, process.env.REACT_APP_SUPABASE_API_KEY!);

function Login() {
  const [email, setEmail] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };


  const handleSignUp = async () => {
    setIsLoading(true)

    const domain = email.split('@')[1];
    const response = await axios.get(process.env.REACT_APP_BACKEND_URL! + '/teams/' + domain);

    if (response.data == null) {
      alert('Error: no team found for domain ' + domain);
      return;
    }

    const teamId = response.data.id

    const { data, error } = await supabase.auth.signInWithOtp({
      email: email,
      options: {
        // set this to false if you do not want the user to be automatically signed up
        shouldCreateUser: true
      },
    })

    if (error) {
      alert("Failed to sign in user: " + error.message)
      return;
    }

    alert('Check your email for the OTP');
    setOtpSent(true);
    setIsLoading(false);
  };

  const handleSubmitOtp = async () => {


    const { data, error } = await supabase.auth.verifyOtp({
      email: email,
      token: otp,
      type: 'email',
    })

    if (error) {
      alert('Error: ' + error.message);
      return;

    }

    if (!data.session) {
      alert('Error: invalid OTP');
      return;
    }

    try {
      const res = await axios.post(process.env.REACT_APP_BACKEND_URL! + '/users/init',
        {
          email: email
        },
        {
          headers: {
            Authorization: `Bearer ${data.session.access_token}`
          }
        }
      );

      if(res.status === 200) {
        navigate('/challenges');
        return;
      }

      if(res.status === 201) {
        navigate('/register');
        return;
      }
    } catch (error) {
      console.error('Error creating profile:', error);
      alert('Failed to create profile: ' + error);
      return;
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-background">
      <Card className="w-[350px]">
        <CardHeader className="space-y-1">
          <div className="flex justify-center mb-4">
            <h1 className="text-4xl font-bold">Trivi</h1>
          </div>
        </CardHeader>
        <CardContent className="grid gap-4">
          <div className="grid gap-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              placeholder="m@example.com"
              value={email}
              onChange={handleEmailChange}
            />
          </div>
          {otpSent && (
            <div className="grid gap-2">
              <Label htmlFor="otp">OTP</Label>
              <Input
                id="otp"
                type="text"
                placeholder="123456"
                value={otp}
                onChange={(event) => setOtp(event.target.value)}
              />
            </div>)}
        </CardContent>
        <CardFooter className="flex flex-col gap-2">
          {isLoading ? (
            <Spinner />
          ) : otpSent ? (
            <Button
              className="w-full"
              onClick={handleSubmitOtp}
            >
              Submit OTP
            </Button>
          ) : (
            <Button
              className="w-full"
              onClick={handleSignUp}
            >
              Login
            </Button>
          )}
        </CardFooter>
      </Card>
    </div>
  );
}

export default Login;