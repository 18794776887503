import React, { useState, useEffect, useContext } from 'react';
import { createClient } from '@supabase/supabase-js';
import axios from 'axios';

const supabase = createClient(process.env.REACT_APP_SUPABASE_URL!, process.env.REACT_APP_SUPABASE_API_KEY!);

const AuthContext = React.createContext<any>(null);

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider(props: any) {
    const [session, setSession] = useState<any>(null);
    const [user, setUser] = useState<any>(null);
    
    const logout = async () => {
        const { error } = await supabase.auth.signOut();
        
        if (error) {
            alert('Error logging out: ' + error.message);
        }

        setSession(null);
        setUser(null);
    }

    useEffect(() => {

        console.log('auth session: ' + JSON.stringify(session)) 

        // Verify the session with supabase 
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session);
            setUser(getUser(session))
        });

        const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
            if (event === 'SIGNED_OUT') {
                setSession(null);
                setUser(null);
            } else if (session) {
                setUser(getUser(session))
                setSession(session);
            }
        })

        return () => {
            subscription.unsubscribe()
        }

    }, []);

    useEffect(() => {
        getUser(session);
    }, [session]);

    // Once the session is verfied, use the bearer token to auth to backend and get the user data
    const getUser = (session: any) => {
        if (!session) return;

        axios.get(
            process.env.REACT_APP_BACKEND_URL! + '/users/',
            {
                headers: {
                    Authorization: `Bearer ${session.access_token}`,
                },
            }
        ).then((response) => {
            setUser(response.data)
        }).catch((error) => {
            console.log(error);
        });
    }

    const value = {
        user,
        session,
        logout
    }

    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    )
}