import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Schedule from './Pages/Schedule';
import Challenges from './Pages/Challenges';
import Login from './Pages/Login';
import Leaderboard from './Pages/Leaderboard';
import Team from './Pages/Team';
import Responses from './Pages/Responses';
import Register from './Pages/Register';
import { createClient } from '@supabase/supabase-js';
import { useAuth } from './Contexts/AuthContext';


import MainLayout from './Layouts/MainLayout';

const supabase = createClient(process.env.REACT_APP_SUPABASE_URL!, process.env.REACT_APP_SUPABASE_API_KEY!);

const App = () => {
  const { session, user } = useAuth();

  // useEffect(() => {
  //   if (session) {
  //     console.log("Session: " + JSON.stringify(session));
  //   }

  //   if(user) {
  //     console.log("User: " + JSON.stringify(user));
  //   }
  // }, [session, user]);


  return (
    <Router>
      {session && user && (
        <Routes>
          {user.onboarding_complete ? (
            <Route element={<MainLayout />}>
              <Route path="/" element={<Navigate to="/challenges" />} />
              <Route path="*" element={<Navigate to="/challenges" />} />
              <Route path="/schedule" element={<Schedule />} />
              <Route path="/challenges" element={<Challenges />} />
              <Route path="/leaderboard" element={<Leaderboard />} />
              <Route path="/team" element={<Team />} />
              <Route path="/responses/:challenge_id" element={<Responses />} />

            </Route>
          ) : (
            <>
              <Route path='/register' element={<Register />} />
              <Route path="*" element={<Navigate to="/register" />} />
            </>
          )}
        </Routes>
      )}

      {!session && (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </Router>
  );
};

export default App;
