import React, { useState, useEffect } from 'react';
import { useAuth } from '../Contexts/AuthContext';
import axios from 'axios';

import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "../components/ui/card";
import { Button } from '../components/ui/button';
import { Label } from '../components/ui/label';
import { Input } from '../components/ui/input';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../components/ui/select";

const Register = () => {
    const { session, user } = useAuth();
    const [name, setName] = useState('');
    const [pods, setPods] = useState<any[]>([]);
    const [selectedPod, setSelectedPod] = useState('');

    useEffect(() => {
        if (session) {
            // Fetch available pods for the user's team
            axios.get(process.env.REACT_APP_BACKEND_URL! + '/pods/all', {
                headers: {
                    Authorization: `Bearer ${session.access_token}`
                }
            }).then((response) => {
                if (response.data) {
                    console.log('POD DATA: ' + JSON.stringify(response.data));
                    setPods(response.data.map((item: any) => ({
                        id: item.id,
                        name: item.name
                    })));
                }
            }).catch((error) => {
                console.log('Error fetching pods:', error);
            });
        }
    }, [session]);

    const handleSubmit = async () => {        
        try {
            await axios.put(process.env.REACT_APP_BACKEND_URL! + '/users/',
                {
                    name: name,
                    pod_id: selectedPod,
                    onboarding_complete: 1
                },
                {
                    headers: {
                        Authorization: `Bearer ${session.access_token}`
                    }
                }
            );

            // Redirect to challenges page after successful profile creation
            window.location.href = '/challenges';
        } catch (error) {
            console.error('Error creating profile:', error);
            alert('Failed to create profile: ' + error);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-background">
            <Card className="w-[350px]">
                <CardHeader className="space-y-1">
                    <CardTitle className="text-2xl text-center">Create Profile</CardTitle>
                </CardHeader>
                <CardContent className="grid gap-4">
                    <div className="grid gap-2">
                        <Label htmlFor="email">Email</Label>
                        <Input
                            id="email"
                            type="email"
                            value={user?.email || ''}
                            disabled
                        />
                    </div>
                    <div className="grid gap-2">
                        <Label htmlFor="name">Name</Label>
                        <Input
                            id="name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter your name"
                        />
                    </div>
                    <div className="grid gap-2">
                        <Label htmlFor="pod">Pod</Label>
                        <Select
                            value={selectedPod}
                            onValueChange={setSelectedPod}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select your pod" />
                            </SelectTrigger>
                            <SelectContent>
                                {pods.map((pod) => (
                                    <SelectItem key={pod.id} value={pod.id}>
                                        {pod.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <Button
                        className="w-full mt-4"
                        onClick={handleSubmit}
                        disabled={!name}
                    >
                        Finish signing up
                    </Button>
                </CardContent>
            </Card>
        </div>
    );
};

export default Register;