import React, { useEffect, useState } from 'react';
import { useAuth } from '../Contexts/AuthContext';
import axios from 'axios';
import { Button } from '../components/ui/button';
import { Textarea } from '../components/ui/textarea';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../components/ui/card"
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "../components/ui/accordion"
import { Badge } from "../components/ui/badge"
import { Link } from "react-router-dom";
import Countdown from 'react-countdown';
import { set } from 'date-fns';
import { time } from 'console';
import { Spinner } from '@chakra-ui/react'


interface Challenge {
    challenge_id: string;
    challenge_question: string;
    challenge_date: Date;
    grading_criteria: string;
    response_id: string | null;
    response_text: string | null;
    response_started_at: Date | null;
    grade_score: number | null;
    grade_critique: string | null;
}

const Challenges = () => {
    const { session, user } = useAuth();
    const [currentChallenge, setCurrentChallenge] = useState<Challenge | null>(null)
    const [currentResponseText, setCurrentResponseText] = useState<string>('')
    const [pastChallenges, setPastChallenges] = useState<Challenge[]>([]);
    const [timesUp, setTimesUp] = useState(false);
    const [grading, setGrading] = useState(false);
    const timerLength = 180000;
    
    // var challengeDate = new Date(response.data.created_at).toISOString().split('T')[0];
    // var todaysDate = new Date().toISOString().split('T')[0]; 

    const countdownRenderer = ({ hours, minutes, seconds, completed }: any) => {
        if (completed) {
            // Render a completed state
            return <Badge>Time's Up</Badge>;
        } else {
            // Render a countdown
            if (minutes < 1) {
                return <Badge variant='destructive' className='font-mono'>{minutes}:{seconds < 10 ? '0' + seconds : seconds}</Badge>;
            } else {
                return <Badge className='font-mono'>{minutes}:{seconds < 10 ? '0' + seconds : seconds}</Badge>;
            }
        }
    };


    const handleCountdownComplete = () => {
        if (currentChallenge?.grade_score) {
            return;
        } else {

            console.log('AUTO SUBMITTING')
            // Auto-submit the response if the user has written something
            if (currentResponseText.trim() !== '') {
                handleSubmitResponse();
            }
            setTimesUp(true);
        }
    }



    // Fetch the challenge of the day and the user's response if it exists
    useEffect(() => {
        if (session) {
            fetchChallenges();
        }

    }, [session]);


    const fetchChallenges = async () => {
        axios.get(process.env.REACT_APP_BACKEND_URL! + '/challenges/past', {
            headers: {
                Authorization: `Bearer ${session.access_token}`
            }
        }).then((response) => {

            if (response.data) {

                console.log(response.data);

                const currentDateObj = new Date()
                const year = currentDateObj.getFullYear();
                const month = String(currentDateObj.getMonth() + 1).padStart(2, '0');
                const day = String(currentDateObj.getDate()).padStart(2, '0');
                const currentDateStr = `${year}-${month}-${day}`;

                response.data.sort((a: Challenge, b: Challenge) => (a.challenge_date < b.challenge_date) ? 1 : -1);

                console.log('response data: ' + JSON.stringify(response.data));
                console.log('TOP RESPONSE DATE: ' + response.data[0].challenge_date.split('T')[0]);


                if (response.data[0].challenge_date.split('T')[0] === currentDateStr) {
                    setCurrentChallenge(response.data[0])
                    if (response.data[0].response_id) {
                        setCurrentResponseText(response.data[0].response_text)

                        const startedAt = new Date(response.data[0].response_started_at);
                        const now = new Date();
                        if (startedAt.getTime() + timerLength < now.getTime()) {
                            setTimesUp(true);
                        }

                    }
                    response.data.shift()
                }

                setPastChallenges(response.data.map((item: any) => ({
                    challenge_id: item.challenge_id,
                    challenge_question: item.challenge_question,
                    challenge_date: item.challenge_date,
                    grading_criteria: item.grading_criteria,
                    response_id: item.response_id,
                    response_text: item.response_text,
                    response_started_at: item.response_started_at,
                    grade_score: item.grade_score,
                    grade_critique: item.grade_critique
                })));

                console.log(response.data);
            }

        }).catch((error) => {
            console.log(error);
        });
    }

    const convertCriteria = async (criteria: string) => {
        try {
          const res = await axios.post(process.env.REACT_APP_BACKEND_URL! + '/challenges/criteria/convert',
            {
              grading_criteria: criteria
            },
            {
              headers: {
                Authorization: `Bearer ${session.access_token}`
              }
            }
          );
    
          return res.data.criteria.criteria;
        } catch (error) {
          console.log(error);
          return;
        }
      }
    
      const gradeResponse = async () => {
        const criteria = await convertCriteria(currentChallenge!.grading_criteria);
        let item: any;
        let maxScore = 0;
        let score = 0;
        let critique = '';
    
        await Promise.all(criteria.map(async (item: any) => {
          console.log('ITEM: ' + JSON.stringify(item));
          try {
            const res = await axios.post(process.env.REACT_APP_BACKEND_URL! + '/challenges/criteria/grade',
              {
                question: currentChallenge!.challenge_question,
                response: currentResponseText,
                points: item.points,
                criterion: item.text
              },
              {
                headers: {
                  Authorization: `Bearer ${session.access_token}`
                }
              }
            );
    
            score += res.data.grade.score;
            maxScore += item.points;
            critique += res.data.grade.critique + ' ';
          } catch (error) {
            console.log(error);
            return null;
          }
        }));
    
        score = Math.floor(score / maxScore * 10);
        
        try {
            await axios.put(process.env.REACT_APP_BACKEND_URL! + '/challenges/responses',
              {
                challenge_id: currentChallenge!.challenge_id,
                response_text: currentResponseText,
                score: score,
                critique: critique
              },
              {
                headers: {
                  Authorization: `Bearer ${session.access_token}`
                }
              }
            );
    
            alert('Challenge submitted successfully!');
            return;
          } catch (error) {
            console.log(error);
            return;
          }
      }


    const handleSubmitResponse = async () => {
        try {
            setGrading(true)
            await gradeResponse();
            setGrading(false);
            window.location.reload();
        } catch (error) {
            console.error('Error submitting response:', error);
            alert('Failed to submit response: ' + error);
            setGrading(false);
        }
    }

    const handleInitResponse = async () => {
        try {
            await axios.post(process.env.REACT_APP_BACKEND_URL! + '/challenges/responses/init', {
                challenge_id: currentChallenge!.challenge_id,
                response_text: '',
            },
                {
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                });

            fetchChallenges();
        } catch (error) {
            console.error('Error submitting response:', error);
            alert('Failed to submit response: ' + error);
        }
    }


    return (
        <>
            <Card>
                <CardHeader>
                    <div className="flex flew-row justify-between">
                        <CardTitle className="text-xl">Today's Challenge</CardTitle>
                        {currentChallenge && currentChallenge.response_id && !currentChallenge.grade_critique && (
                            <Countdown
                                className="justify-end"
                                date={new Date(currentChallenge.response_started_at!).getTime() + timerLength}
                                intervalDelay={0}
                                renderer={countdownRenderer}
                                onComplete={handleCountdownComplete}
                            />
                        )}
                    </div>

                </CardHeader>
                <CardContent>
                    {currentChallenge ? (

                        currentChallenge.response_id ? (
                            <div>
                                <div>
                                    <p className="mt-10 scroll-m-20 font-semibold text-md tracking-tight transition-colors first:mt-0">
                                        {currentChallenge.challenge_question}
                                    </p>
                                </div>



                                {currentChallenge.grade_critique ? (
                                    <>
                                        <div className="mt-4 italic">
                                            <p>{currentChallenge.response_text}</p>
                                        </div>

                                        <div className="mt-4 bg-gray-100 p-4 rounded-md">
                                            <p className="text-md font-semibold">
                                                Score: {currentChallenge.grade_score}
                                            </p>
                                            <p className="mt-2">
                                                {currentChallenge.grade_critique}
                                            </p>
                                        </div>
                                        <Link
                                            to={`/responses/${currentChallenge.challenge_id}`}
                                            className="text-blue-500 hover:text-blue-700 mt-2 inline-block"
                                        >
                                            View Peer Responses
                                        </Link>
                                    </>
                                ) : (<></>)}

                                {!currentChallenge.grade_critique && timesUp ? (
                                    <div className="mt-4">
                                        <p className="text-md italic">
                                            You didn't submit a response...
                                        </p>
                                    </div>
                                ) : (<></>)}

                                {!currentChallenge.grade_critique && !timesUp ? (
                                    <div className="mt-10">
                                        <Textarea className="mt-10"
                                            value={currentResponseText}
                                            onChange={(e) => setCurrentResponseText(e.target.value)}
                                            readOnly={timesUp ? true : false}
                                        />
                                        <Button className="mt-4 mx-auto"
                                            disabled={timesUp || currentResponseText === '' ? true : false}
                                            onClick={handleSubmitResponse}>
                                            {grading ? <span>Submitting...  <Spinner size="xs" /></span> : "Submit Response"}
                                        </Button>
                                    </div>
                                ) : (<></>)}

                            </div>
                        ) : (
                            <Button onClick={handleInitResponse}>
                                Start Challenge
                            </Button>
                        )
                    ) : (
                        <p>No challenge posted yet!</p>
                    )}
                </CardContent>
            </Card>

            <h1 className="scroll-m-20 text-3xl font-bold mt-10 mb-5">Past challenges</h1>
            <div>
                {pastChallenges.map((challenge) => (
                    <div key={challenge.challenge_id}>
                        <Accordion type="single" collapsible>
                            <AccordionItem value="item-1">
                                <AccordionTrigger>
                                    <Badge className="ml-2">{challenge.challenge_date.toString().split('T')[0]}</Badge>
                                </AccordionTrigger>
                                <AccordionContent>
                                    <div className="px-4">
                                        <p className="mt-10 scroll-m-20 text-md tracking-tight transition-colors first:mt-0 font-semibold">
                                            {challenge.challenge_question}
                                        </p>

                                        <div className="mt-4">
                                            {challenge.grade_critique ? (
                                                <p className="mt-4">
                                                    {challenge.response_text}
                                                </p>
                                            ) : (
                                                <p className="mt-4 italic">
                                                    You didn't submit a response...
                                                </p>
                                            )}
                                        </div>

                                        {challenge.grade_critique ? (
                                            <div className='mt-4 bg-gray-100 p-4 rounded-md'>
                                                <p className="text-md font-semibold">
                                                    Score: {challenge.grade_score}
                                                </p>
                                                <p className="mt-2">
                                                    {challenge.grade_critique}
                                                </p>
                                            </div>
                                        ) : (<></>)}

                                        <Link
                                            to={`/responses/${challenge.challenge_id}`}
                                            className="text-blue-500 hover:text-blue-700 mt-2 inline-block"
                                        >
                                            View Peer Responses
                                        </Link>

                                        {/* <p className="mt-4">
                                            {challenge.response_text ? challenge.response_text : "You didn't submit a response..."}
                                        </p>
                                        <p className="mt-4">
                                            Score: {challenge.grade_score}
                                        </p>
                                        <div>
                                            <p className="mt-2">
                                                Critique: {challenge.grade_critique}
                                            </p>
                                            <Link
                                                to={`/responses/${challenge.challenge_id}`}
                                                className="text-blue-500 hover:text-blue-700 mt-2 inline-block"
                                            >
                                                View Responses
                                            </Link>
                                        </div> */}
                                    </div>
                                </AccordionContent>
                            </AccordionItem>
                        </Accordion>
                    </div>
                ))}
            </div>
        </>
    )
};

export default Challenges;