import React, { useState, useEffect } from "react";
import { useAuth } from "../Contexts/AuthContext";
import axios from "axios";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs"
import { Button } from "../components/ui/button"
import { Label } from "../components/ui/label"
import { Input } from "../components/ui/input"

import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../components/ui/table"

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../components/ui/select"

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogFooter,
    DialogClose
} from "../components/ui/dialog"




type User = {
    id: string
    role: string
    pod_id: string
    pod_name: string
    email: string
}

enum UserRole {
    TEAM_ADMIN = "TEAM_ADMIN",
    POD_MEMBER = "POD_MEMBER",
}

type Pod = {
    id: string
    name: string
}


const EditPodDialog = () => {



}

const Team = () => {
    const { session, user } = useAuth();
    const [users, setUsers] = useState<User[]>([]);
    const [pods, setPods] = useState<Pod[]>([]);
    const [editPodDialogName, setEditPodDialogName] = useState('');
    const [newPodDialogName, setNewPodDialogName] = useState('');
    

    useEffect(() => {
        if (session && user) {
            getUsers();
            getPods();
        }
    }, [session, user]);

    const getUsers = () => {
        axios.get(process.env.REACT_APP_BACKEND_URL! + '/users/all', {
            headers: {
                Authorization: `Bearer ${session.access_token}`
            }
        }).then((response) => {

            if (response.data) {
                console.log(response.data);

                setUsers(response.data.map((item: any) => ({
                    id: item.id,
                    role: item.role,
                    pod_id: item.pod_id,
                    pod_name: item.pod_name,
                    email: item.email
                })))
            }

        }).catch((error) => {
            console.log(error);
        });
    }

    const getPods = () => {
        axios.get(process.env.REACT_APP_BACKEND_URL! + '/pods/all', {
            headers: {
                Authorization: `Bearer ${session.access_token}`
            }
        }).then((response) => {

            if (response.data) {
                console.log('POD DATA: ' + JSON.stringify(response.data));

                setPods(response.data.map((item: any) => ({
                    id: item.id,
                    name: item.name
                })))
            }

        }).catch((error) => {
            console.log(error);
        });
    }

    const updateUserRole = async (targetUserId: string, targetRole: string) => {

        try {
            const response = await axios.post(process.env.REACT_APP_BACKEND_URL! + '/users/role',
                {
                    target_user_id: targetUserId,
                    target_role: targetRole
                },
                {
                    headers: {
                        Authorization: `Bearer ${session.access_token}`
                    }
                }
            )

            if (targetRole == 'TEAM_ADMIN') {
                updateUserPod(targetUserId, null);
                return;
            } else {
                getUsers();
            }
        } catch (error) {
            alert('Error updating user role: ' + error);
            return;
        }
    }


    const updateUserPod = async (targetUserId: string, targetPodId: string | null) => {

        try {
            const response = await axios.post(process.env.REACT_APP_BACKEND_URL! + '/users/pod',
                {
                    target_user_id: targetUserId,
                    target_pod_id: targetPodId
                },
                {
                    headers: {
                        Authorization: `Bearer ${session.access_token}`
                    }
                }
            )

            getUsers();
        } catch (error) {
            alert('Error updating user pod: ' + error);
            return;
        }
    }

    const updatePod = async (targetPodId: string, targetPodName: string) => {

        console.log('UPDATE POD: ' + targetPodId + ' ' + targetPodName);

        try {
            const response = await axios.put(process.env.REACT_APP_BACKEND_URL! + '/pods/',
                {
                    pod_id: targetPodId,
                    pod_name: targetPodName
                },
                {
                    headers: {
                        Authorization: `Bearer ${session.access_token}`
                    }
                }
            )

            getUsers()
            getPods();
        } catch (error) {
            alert('Error updating pod: ' + error);
            return;
        }
    }

    const createPod = async (podName: string) => {
        try {
            await axios.post(process.env.REACT_APP_BACKEND_URL! + '/pods/',
                {
                    pod_name: podName
                },
                {
                    headers: {
                        Authorization: `Bearer ${session.access_token}`
                    }
                }
            );
            setNewPodDialogName('');
            getPods();
        } catch (error) {
            alert('Error creating pod: ' + error);
        }
    };

    if (user && user.role != 'TEAM_ADMIN') {
        return (
            <div>
                <h1>Unauthorized</h1>
            </div>
        )
    }

    return (
        (user && user.role == 'TEAM_ADMIN' && users.length > 0) && (
            <Tabs defaultValue="users" className="w-2/3 mx-auto">
                <TabsList className="grid w-md grid-cols-2">
                    <TabsTrigger value="users">Users</TabsTrigger>
                    <TabsTrigger value="pods">Pods</TabsTrigger>
                </TabsList>
                <TabsContent value="users">
                    <Table className="">
                        <TableHeader>
                            <TableRow>
                                <TableHead >Email</TableHead>
                                <TableHead>Pod</TableHead>
                                <TableHead>Role</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {users.map(user => (
                                <TableRow key={user.id}>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>
                                        <Select
                                            disabled={user.role == 'TEAM_ADMIN'}
                                            value={user.pod_id}
                                            onValueChange={(value) => updateUserPod(user.id, value)}
                                        >
                                            <SelectTrigger className="w-[180px]">
                                                <SelectValue />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {pods.map(pod => (
                                                    <SelectItem key={pod.id} value={pod.id}>
                                                        {pod.name}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </TableCell>
                                    <TableCell>
                                        <Select
                                            value={user.role}
                                            onValueChange={(value) => updateUserRole(user.id, value)}
                                        >
                                            <SelectTrigger className="w-[180px]">
                                                <SelectValue />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {Object.values(UserRole).map(role => (
                                                    <SelectItem key={role} value={role}>
                                                        {role}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TabsContent>
                <TabsContent value="pods">
                    <Table className="">
                        <TableHeader>
                            <TableRow>
                                <TableHead >Pod Name</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {pods.map(pod => (
                                <TableRow key={pod.id}>
                                    <TableCell>{pod.name}</TableCell>
                                    <TableCell>
                                        <Dialog>
                                            <DialogTrigger asChild>
                                                <Button variant="outline">Edit</Button>
                                            </DialogTrigger>
                                            <DialogContent className="sm:max-w-[425px]">
                                                <DialogHeader>
                                                    <DialogTitle>Edit Pod</DialogTitle>
                                                    <DialogDescription>
                                                        Make changes to the Pod here.
                                                    </DialogDescription>
                                                </DialogHeader>
                                                <div className="grid gap-4 py-4">
                                                    <div className="grid grid-cols-4 items-center gap-4">
                                                        <Label htmlFor="name" className="text-right">
                                                            Pod Name
                                                        </Label>
                                                        <Input id="name" placeholder={pod.name} value={editPodDialogName} onChange={(e) => setEditPodDialogName(e.target.value)} className="col-span-3" />
                                                    </div>
                                                </div>
                                                <DialogFooter>
                                                    <DialogClose asChild>
                                                        <Button onClick={() => updatePod(pod.id, editPodDialogName)}>Save</Button>
                                                    </DialogClose>
                                                </DialogFooter>
                                            </DialogContent>
                                        </Dialog>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Dialog>
                        <DialogTrigger asChild>
                            <Button className="mt-4">Add Pod</Button>
                        </DialogTrigger>
                        <DialogContent className="sm:max-w-[425px]">
                            <DialogHeader>
                                <DialogTitle>Add New Pod</DialogTitle>
                                <DialogDescription>
                                    Create a new pod by entering its name below.
                                </DialogDescription>
                            </DialogHeader>
                            <div className="grid gap-4 py-4">
                                <div className="grid grid-cols-4 items-center gap-4">
                                    <Label htmlFor="name" className="text-right">
                                        Name
                                    </Label>
                                    <Input
                                        id="name"
                                        value={newPodDialogName}
                                        onChange={(e) => setNewPodDialogName(e.target.value)}
                                        className="col-span-3"
                                    />
                                </div>
                            </div>
                            <DialogFooter>
                                <DialogClose asChild>
                                    <Button onClick={() => createPod(newPodDialogName)}>Create Pod</Button>
                                </DialogClose>
                            </DialogFooter>
                        </DialogContent>
                    </Dialog>
                </TabsContent>
            </Tabs>






        )
    );
}





export default Team;