import React, { ReactNode } from 'react';
import { Outlet, Link } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"
import { useAuth } from "../Contexts/AuthContext";



const MainLayout = () => {

  const { session, user, logout } = useAuth();

  return (
    (session && user) && (
      <>
        <header className="bg-surface-100 border-b border-border">
          <div className="container mx-auto py-4 px-6 flex flex-row justify-between">
            <h1 className="text-2xl font-bold">Trivi</h1>
            <div className="self-end">
              <DropdownMenu>
                <DropdownMenuTrigger>Menu</DropdownMenuTrigger>
                <DropdownMenuContent>
                  <Link to='/challenges'><DropdownMenuItem>Challenges</DropdownMenuItem></Link>
                  <Link to='/leaderboard'><DropdownMenuItem>Leaderboard</DropdownMenuItem></Link>
                  {user.role == 'TEAM_ADMIN' && (
                    <>
                      <Link to='/schedule'><DropdownMenuItem>Schedule</DropdownMenuItem></Link>
                      <Link to='/team'><DropdownMenuItem>Team</DropdownMenuItem></Link>
                    </>
                  )}
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onClick={logout}>Logout</DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </header>

        <div className="container mx-auto pt-24">
          <Outlet />
        </div>
      </>
    )
  )
}

export default MainLayout