import React, { useEffect, useState } from 'react';
import { useAuth } from '../Contexts/AuthContext';
import axios from 'axios';

import { Spinner } from '@chakra-ui/react';
import { CopyIcon } from "@chakra-ui/icons";
import { Calendar } from "../components/ui/calendar";
import { Label } from '../components/ui/label';
import { Button } from '../components/ui/button';
import { Textarea } from '../components/ui/textarea';

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card"
import { set } from 'date-fns';

type Challenge = {
  challenge_id: string
  challenge_question: string
  challenge_date: string
  ideal_response: string
  grading_criteria: string
}


const Schedule = () => {

  const { session, user } = useAuth();
  const [question, setQuestion] = useState('');
  const [example, setExample] = useState('');
  const [criteria, setCriteria] = useState('');
  const [updatingChallenge, setUpdatingChallenge] = useState(false);
  const [testingResponse, setTestingResponse] = useState(false);
  const [inferringCriteria, setInferringCriteria] = useState(false);


  const dateStringToObject = (date: string) => {
    const dateParts = date.split('-').map(part => parseInt(part));
    return new Date(dateParts[0], dateParts[1] - 1, dateParts[2], 0, 0, 0, 0);
  }

  const dateObjectToString = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const currentDateObj = new Date();
  const currentDateStr = dateObjectToString(currentDateObj);
  // const currentDate = currentDateISO.getFullYear() + '-' + String(currentDateISO.getMonth() + 1).padStart(2, '0') + '-' + String(currentDateISO.getDate()).padStart(2, '0');

  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date(currentDateObj.getFullYear(), currentDateObj.getMonth(), currentDateObj.getDate()))
  const [selectedDate, setSelectedDate] = useState<Date>()
  const [challengesInSelectedMonth, setChallengesInSelectedMonth] = useState<Challenge[]>([])
  const [challengeScheduledForSelectedDate, setChallengeScheduledForSelectedDate] = useState<Challenge | null>(null)

  const [editChallengeDialogQuestion, setEditChallengeDialogQuestion] = useState<string>('')
  const [editChallengeDialogExample, setEditChallengeDialogExample] = useState<string>('')
  const [editChallengeDialogCriteria, setEditChallengeDialogCriteria] = useState<string>('')
  const [testResponse, setTestResponse] = useState<string>('')
  const [testResult, setTestResult] = useState<{ score: number, critique: string } | null>(null)

  const formatDateNatural = (date: Date) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const dayName = days[date.getDay()];
    const monthName = months[date.getMonth()];
    const day = date.getDate();

    // Add ordinal suffix
    const ordinalSuffix = (n: number) => {
      if (n > 3 && n < 21) return 'th';
      switch (n % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    return `${dayName}, ${monthName} ${day}${ordinalSuffix(day)}`;
  };

  // Set the selected date to the current date
  useEffect(() => {
    setSelectedDate(new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), selectedMonth.getDate()));
  }, []);

  // Get all challenges for the selected month
  useEffect(() => {
    setSelectedDate(undefined);
    if (session && user) {
      getSelectedMonthChallenges();
    }
  }, [selectedMonth]);

  // Anytime the selected month challenges change, update the 
  useEffect(() => {
    const challenges = challengesInSelectedMonth.filter((challenge) => challenge.challenge_date === selectedDate?.toISOString().split('T')[0]);

    if (challenges.length > 0) {
      setChallengeScheduledForSelectedDate(challenges[0]);
      setEditChallengeDialogQuestion(challenges[0].challenge_question);
      setEditChallengeDialogExample(challenges[0].ideal_response);
      setEditChallengeDialogCriteria(challenges[0].grading_criteria);
    } else {
      setChallengeScheduledForSelectedDate(null);
    }
  }, [selectedDate, challengesInSelectedMonth]);

  const getAllDatesInMonth = (month: Date) => {
    const dates: Date[] = []
    const start = new Date(month.getFullYear(), month.getMonth(), 1)
    const end = new Date(month.getFullYear(), month.getMonth() + 1, 0)

    let day = start
    while (day <= end) {
      dates.push(new Date(day.getTime()))
      day.setDate(day.getDate() + 1)
    }

    return dates
  }

  const getSelectedMonthChallenges = () => {

    const startDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1).toISOString().split('T')[0];
    const endDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0).toISOString().split('T')[0];

    axios.get(process.env.REACT_APP_BACKEND_URL! + '/challenges/questions?startDate=' + startDate + '&endDate=' + endDate, {
      headers: {
        Authorization: `Bearer ${session.access_token}`
      }
    }).then((response) => {
      setChallengesInSelectedMonth(response.data.map((item: any) => ({
        challenge_id: item.id,
        challenge_question: item.challenge_question,
        challenge_date: item.challenge_date,
        ideal_response: item.ideal_response,
        grading_criteria: item.grading_criteria
      })));
    }).catch((error) => {
      console.log(error);
    });

  }


  if (user && user.role != 'TEAM_ADMIN') {
    return (
      <div>
        <h1>Unauthorized</h1>
      </div>
    )
  }

  const handleInferCriteria = async () => {
    try {
      setInferringCriteria(true);
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL! + '/challenges/criteria/infer',
        {
          challenge_question: editChallengeDialogQuestion,
          ideal_response: editChallengeDialogExample
        },
        {
          headers: {
            Authorization: `Bearer ${session.access_token}`
          }
        }
      );
      setEditChallengeDialogCriteria(response.data.criteria.content);
      setInferringCriteria(false);
    } catch (error) {
      console.error('Error inferring criteria:', error);
      alert('Failed to infer criteria: ' + error);
      setInferringCriteria(false);
    }
  };




  const handleUpdateChallenge = async (challenge_id: string) => {

    console.log('UPDATE CHALLENGE: ' + challenge_id);

    try {
      setUpdatingChallenge(true);
      await axios.put(process.env.REACT_APP_BACKEND_URL! + '/challenges/',
        {
          challenge_id: challenge_id,
          challenge_question: editChallengeDialogQuestion,
          ideal_response: editChallengeDialogExample,
          grading_criteria: editChallengeDialogCriteria,
          challenge_date: selectedDate!.toISOString().split('T')[0],
        },
        {
          headers: {
            Authorization: `Bearer ${session.access_token}`
          }
        }
      );
      setUpdatingChallenge(false);
      getSelectedMonthChallenges();
      alert('Challenge updated successfully!');
    } catch (error) {
      setUpdatingChallenge(false);
      console.log(error);
      alert('Failed to update challenge: ' + error);
    }
  }


  const handleAddChallenge = async (date: Date) => {
    try {
      await axios.post(process.env.REACT_APP_BACKEND_URL! + '/challenges/',
        {
          challenge_question: '',
          ideal_response: '',
          grading_criteria: '',
          challenge_date: date.toISOString().split('T')[0],
        },
        {
          headers: {
            Authorization: `Bearer ${session.access_token}`
          }
        }
      );

      getSelectedMonthChallenges();
    } catch (error) {
      console.log(error);
    }
  }

  const handleDeleteChallenge = async (challenge_id: string) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this challenge? This action cannot be undone.');

    if (!confirmDelete) {
      return;
    }

    try {
      await axios.delete(process.env.REACT_APP_BACKEND_URL! + '/challenges/' + challenge_id, {
        headers: {
          Authorization: `Bearer ${session.access_token}`
        }
      });

      // Refresh the challenges list
      getSelectedMonthChallenges();

      // Clear the current challenge
      setChallengeScheduledForSelectedDate(null);
      setEditChallengeDialogQuestion('');
      setEditChallengeDialogExample('');
      setEditChallengeDialogCriteria('');

      alert('Challenge deleted successfully!');
    } catch (error) {
      console.error('Error deleting challenge:', error);
      alert('Failed to delete challenge: ' + error);
    }
  };

  const handleCopyChallenge = () => {
    const challengeText = `${editChallengeDialogQuestion}==${editChallengeDialogExample}==${editChallengeDialogCriteria}`;
    navigator.clipboard.writeText(challengeText);
  };

  const handlePasteChallenge = async (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    // Get clipboard text
    const clipboardText = e.clipboardData.getData('text');

    // Split by the separator and check if we have all three parts
    const parts = clipboardText.split('==');
    if (parts.length === 3) {
      e.preventDefault();
      const [question, idealResponse, gradingCriteria] = parts;
      setEditChallengeDialogQuestion(question);
      setEditChallengeDialogExample(idealResponse);
      setEditChallengeDialogCriteria(gradingCriteria);
    }
  };

  const convertCriteria = async (criteria: string) => {
    try {
      const res = await axios.post(process.env.REACT_APP_BACKEND_URL! + '/challenges/criteria/convert',
        {
          grading_criteria: criteria
        },
        {
          headers: {
            Authorization: `Bearer ${session.access_token}`
          }
        }
      );

      return res.data.criteria.criteria;
    } catch (error) {
      console.log(error);
      return;
    }
  }

  const gradeTestResponse = async () => {
    const criteria = await convertCriteria(editChallengeDialogCriteria);
    let item: any;
    let maxScore = 0;
    let score = 0;
    let critique = '';

    await Promise.all(criteria.map(async (item: any) => {
      console.log('ITEM: ' + JSON.stringify(item));
      try {
        const res = await axios.post(process.env.REACT_APP_BACKEND_URL! + '/challenges/criteria/grade',
          {
            question: editChallengeDialogQuestion,
            response: testResponse,
            points: item.points,
            criterion: item.text
          },
          {
            headers: {
              Authorization: `Bearer ${session.access_token}`
            }
          }
        );

        score += res.data.grade.score;
        maxScore += item.points;
        critique += res.data.grade.critique + ' ';
      } catch (error) {
        console.log(error);
        return null;
      }
    }));

    score = Math.floor(score / maxScore * 10);
    setTestResult({ score: score, critique: critique });
  }

  const handleSubmitTestResponse = async () => {
    try{ 
      setTestingResponse(true);
      await gradeTestResponse();
      setTestingResponse(false);
      return;
    } catch (error) {
      console.error('Error submitting test response:', error);
      setTestingResponse(false);
      return;
    }
  }



  return (
    (user && user.role == 'TEAM_ADMIN') && (
      <div className="grid lg:grid-cols-5 md:grid-cols-3 justify-center w-7/8 mx-auto">
        <div className="lg:col-span-2 md:col-span-2 mx-auto">
          <Calendar
            mode="single"
            selected={selectedDate}
            onSelect={setSelectedDate}
            className="rounded-md"
            month={selectedMonth}
            onMonthChange={setSelectedMonth}
            modifiers={{
              past: getAllDatesInMonth(selectedMonth).filter((date) => dateObjectToString(date) < currentDateStr),
              pastHasChallenge: challengesInSelectedMonth.filter((item) => item.challenge_date < currentDateStr).map((item) => dateStringToObject(item.challenge_date)),
              currentOrFutureHasChallenge: challengesInSelectedMonth.filter((item) => item.challenge_date >= currentDateStr).map((item) => dateStringToObject(item.challenge_date)),
            }}
            modifiersClassNames={{
              past: 'text-muted-foreground opacity-50',
              pastHasChallenge: 'bg-green-100',
              currentOrFutureHasChallenge: 'bg-green-200 hover:bg-green-300',
            }}
          />
        </div>
        <div className="lg:col-span-3 md:col-span-2">
          <Card>
            <CardHeader>
              <div className="flex flex-row justify-between items-center">
                <CardTitle className="text-xl">{selectedDate ? "Challenge for " + formatDateNatural(selectedDate) : "Choose a date"}</CardTitle>
                {challengeScheduledForSelectedDate && (
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={handleCopyChallenge}
                    className="h-8 w-8 active:bg-gray-600"
                    title="Copy challenge details"
                  >
                    <CopyIcon className="h-4 w-4" />
                  </Button>
                )}
              </div>
            </CardHeader>
            <CardContent>
              {selectedDate && challengeScheduledForSelectedDate ? (
                <div>
                  <div>
                    <div>
                      <div>
                        <Label>Question</Label>
                        <Textarea className="mt-1"
                          value={editChallengeDialogQuestion}
                          onChange={(e) => setEditChallengeDialogQuestion(e.target.value)}
                          onPaste={handlePasteChallenge}
                        />
                      </div>
                      <div className="mt-4">
                        <Label>Ideal Response</Label>
                        <Textarea className="mt-1"
                          value={editChallengeDialogExample}
                          onChange={(e) => setEditChallengeDialogExample(e.target.value)}
                        />
                      </div>
                      <div className="mt-4">
                        <div className="flex flex-row items-center mb-2">
                          <Label>Grading Criteria</Label>
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={handleInferCriteria}
                            disabled={inferringCriteria || !editChallengeDialogQuestion || !editChallengeDialogExample || editChallengeDialogCriteria !== ''}
                            className="ml-1 h-6 w-10"
                          >
                            {inferringCriteria ? <span><Spinner size='xs' /></span> : 'Infer'}
                          </Button>
                        </div>
                        <Textarea className="mt-1"
                          value={editChallengeDialogCriteria}
                          onChange={(e) => setEditChallengeDialogCriteria(e.target.value)}
                        />
                        {dateObjectToString(selectedDate!) >= currentDateStr && (
                          <div className="flex flex-row justify-between mt-6">
                            <Button
                              onClick={() => handleUpdateChallenge(challengeScheduledForSelectedDate.challenge_id)}
                              disabled={updatingChallenge}
                            >
                              {updatingChallenge ? <span>Updating... <Spinner size='xs' /></span> : 'Update'}
                            </Button>
                            <Button
                              onClick={() => handleDeleteChallenge(challengeScheduledForSelectedDate.challenge_id)}
                              variant="destructive"
                            >
                              Delete
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {selectedDate ? (
                    <>
                      {dateObjectToString(selectedDate) >= currentDateStr ? (

                        <Button
                          onClick={() => handleAddChallenge(selectedDate)}
                          className="mt-4 mx-auto"
                        >
                          Add Challenge
                        </Button>) : (
                        <p className="italic">No challenge was scheduled for this date</p>)}
                    </>
                  ) : (
                    <p>No date selected</p>
                  )}
                </div>

              )}
            </CardContent>
          </Card>

          {selectedDate && challengeScheduledForSelectedDate && (
            <Card className="mt-4 mb-10">
              <CardHeader>
                <CardTitle className="text-xl">Test Response</CardTitle>
                <CardDescription>Test a response against the grading criteria</CardDescription>
                <div>
                  <Textarea
                    className="mt-1"
                    value={testResponse}
                    onChange={(e) => setTestResponse(e.target.value)}
                    placeholder="Enter a response to test..."
                  />
                  <Button
                    onClick={handleSubmitTestResponse}
                    className="mt-4"
                    disabled={testingResponse}
                  >
                    {testingResponse ? <span>Testing... <Spinner size='xs' /></span> : 'Test Response'}
                  </Button>
                </div>
              </CardHeader>
              <CardContent>
                {testResult && (
                  <div className="mt-4">
                    <div className="font-semibold">Score: {testResult.score}</div>
                    <div className="mt-2">
                      <div className="mt-1 p-4 bg-gray-50 rounded-md">{testResult.critique}</div>
                    </div>
                  </div>
                )}
              </CardContent>
            </Card>
          )}

        </div>
      </div>
    )
  );
};

export default Schedule;